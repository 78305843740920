<template>
  <div>
    <div class="mt-6 d-flex align-center justify-space-between">
      <div @click="confirmacaoEditarInstrumentoPadrao">
        <input-text
          :value="instrumentoPadraoAtual"
          readonly
        >
          <template #prepend-inner>
            <div class="mt-1 primary-grey-600--text">
              {{ $t('modulos.calibracao.valores.instrumento_padrao') }}:
            </div>
          </template>
        </input-text>
      </div>

      <div v-if="!podeEditar">
        <botao-padrao
          class="ml-2"
          color="secondary"
          :tooltip="
            $t('modulos.calibracao.valores.botoes.tooltip.visualizar_historico')
          "
          outlined
          :disabled="!ultimaCalibracaoId"
          @click="abrirVisualizarDadosHistorico"
        >
          <v-icon> $fileSearchOutline </v-icon>
        </botao-padrao>
      </div>
      <div
        v-else-if="podeEditar"
        class="d-flex align-center"
      >
        <botao-padrao
          v-if="!statusOCR && utilizaOcr"
          class="mr-2"
          color="secondary"
          outlined
          @click="iniciarCapturaOCR"
        >
          <v-icon class="mr-2">
            $playCircle
          </v-icon>
          {{ $t('modulos.calibracao.valores.botoes.iniciar_ocr') }}
        </botao-padrao>
        <botao-padrao
          v-if="statusOCR"
          class="mr-2"
          color="red"
          outlined
          @click="interrromperCapturaOCR"
        >
          <v-icon class="mr-2">
            $stopCircle
          </v-icon>
          {{ $t('modulos.calibracao.valores.botoes.parar_ocr') }}
        </botao-padrao>
        <dropdown-padrao
          :tooltip="$t('modulos.calibracao.valores.botoes.tooltip.weight')"
          seta
          color="secondary"
          outlined
        >
          <template #botao>
            <v-icon>$weight</v-icon>
          </template>
          <dropdown-padrao-item
            :disabled="!temSMP"
            @click="confirmacaoEditarInstrumentoPadrao"
          >
            {{ $t('modulos.calibracao.valores.botoes.editar') }}
          </dropdown-padrao-item>
          <dropdown-padrao-item
            :disabled="!tabelaValoresAtual.instrumentosAlterados"
            @click="confirmarLimparInstrumentoPadrao"
          >
            {{ $t('modulos.calibracao.valores.botoes.limpar') }}
          </dropdown-padrao-item>
          <dropdown-padrao-item
            :disabled="!temSMP"
            @click="confirmacaoLimparSMP"
          >
            {{ $t('modulos.calibracao.valores.botoes.limpar_smp') }}
          </dropdown-padrao-item>
          <dropdown-padrao-item
            :disabled="!temSMC"
            @click="confirmacaoLimparSMC"
          >
            {{ $t('modulos.calibracao.valores.botoes.limpar_smc') }}
          </dropdown-padrao-item>
          <dropdown-padrao-item
            :disabled="!temSMP"
            @click="copiarColuna('SMP')"
          >
            {{ $t('modulos.calibracao.valores.botoes.copiar_smp') }}
          </dropdown-padrao-item>
          <dropdown-padrao-item
            :disabled="!temSMC"
            @click="copiarColuna('SMC')"
          >
            {{ $t('modulos.calibracao.valores.botoes.copiar_smc') }}
          </dropdown-padrao-item>
          <dropdown-padrao-item @click="copiarHistorico()">
            {{ $t('modulos.calibracao.valores.botoes.copiar_historico') }}
          </dropdown-padrao-item>
        </dropdown-padrao>
        <botao-padrao
          class="ml-2"
          color="secondary"
          :tooltip="
            $t('modulos.calibracao.valores.botoes.tooltip.conversor_unidades')
          "
          outlined
          @click="abrirParaConversorDeUnidadeDeMedida"
        >
          <v-icon> $calculatorVariantOutline </v-icon>
        </botao-padrao>
        <botao-padrao
          class="ml-2"
          color="secondary"
          :tooltip="
            $t('modulos.calibracao.valores.botoes.tooltip.visualizar_historico')
          "
          outlined
          :disabled="!ultimaCalibracaoId"
          @click="abrirVisualizarDadosHistorico"
        >
          <v-icon> $fileSearchOutline </v-icon>
        </botao-padrao>
        <botao-padrao
          class="ml-2"
          :tooltip="$t('modulos.calibracao.valores.botoes.tooltip.instrumento')"
          color="secondary"
          outlined
          @click="abrirEdicaoDoInstrumento"
        >
          <v-icon> $rulerSquareCompass </v-icon>
        </botao-padrao>
      </div>
    </div>
    <v-form :readonly="!podeEditar">
      <v-simple-table
        :key="keyTabelaValores"
        dense
        class="mt-3 tabela-dinamica-calibracao"
      >
        <thead>
          <tr>
            <th
              scope="rol"
              class="coluna-propriedades"
            >
              {{ $t('modulos.calibracao.valores.cabecalho') }}
            </th>
            <th
              scope="rol"
              class="coluna-propriedades"
            >
              {{ $t('modulos.calibracao.valores.ponto_calibracao') }}
            </th>
            <th
              v-for="coluna in colunas"
              :key="`th-${coluna.id}`"
              scope="rol"
            >
              {{ coluna.cabecalho }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{ $t('modulos.calibracao.valores.sentido') }}</td>
            <td />
            <td
              v-for="coluna in colunas"
              :key="`td-${coluna.id}`"
            >
              <v-icon v-if="temSentido(coluna)">
                {{ retornarIconeDirecao(coluna.sentido) }}
              </v-icon>
            </td>
          </tr>
          <tr>
            <td>{{ $t('modulos.calibracao.valores.variavel') }}</td>
            <td />
            <td
              v-for="coluna in colunas"
              :key="`td-${coluna.id}`"
            >
              {{ coluna.formulaVariavel?.nome }}
            </td>
          </tr>
        </tbody>
        <tbody class="linhas">
          <tr
            v-for="linha in tabelaValoresAtual.linhas"
            :key="`tr-${linha.id}${linha.pontoCalibracao.id}`"
          >
            <td>
              <dropdown-padrao
                text
                color="secondary"
              >
                <template #botao>
                  <v-icon> $dotsVertical </v-icon>
                </template>
                <dropdown-padrao-item @click="depurarLinha(linha)">
                  {{ $t('modulos.calibracao.valores.botoes.depurar') }}
                </dropdown-padrao-item>
              </dropdown-padrao>
            </td>
            <td
              :class="
                !validarEstePonto(linha.pontoCalibracao.valor)
                  ? 'red--text'
                  : ''
              "
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span v-if="!validarEstePonto(linha.pontoCalibracao.valor)">
                    <v-icon
                      color="red"
                      v-bind="attrs"
                      v-on="on"
                    >
                      $alertCircleOutline
                    </v-icon>
                  </span>
                </template>
                <span>{{
                  $t('modulos.calibracao.valores.ponto_invalido')
                }}</span>
              </v-tooltip>
              {{ linha.pontoCalibracao.valor | formatarNumeroComPrecisao }} -
              {{ linha.pontoCalibracao?.unidadeMedida?.nome }}
            </td>
            <td
              v-for="coluna in colunas"
              :key="`th-${coluna.id}`"
            >
              <template v-if="ehLoading(coluna)">
                <div>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <span>
                        <v-progress-circular
                          v-if="linha.calculando"
                          indeterminate
                          color="primary"
                          :size="20"
                          :width="4"
                          v-bind="attrs"
                          v-on="on"
                        />
                      </span>
                    </template>
                    <span>{{
                      $t('modulos.calibracao.valores.calculando')
                    }}</span>
                  </v-tooltip>
                </div>
              </template>
              <template v-if="ehResultado(coluna)">
                <div>
                  {{
                    {
                      valor: buscarResultado(linha, coluna.id),
                      sistema: tabelaValoresAtual.unidadeMedidaSistema,
                      formula: coluna.formula.nome,
                    } | formatarResultado
                  }}
                </div>
              </template>
              <template v-if="temTexto(coluna)">
                <div>{{ linha[coluna.indiceTexto] }}</div>
              </template>
              <template v-if="temUnidade(coluna)">
                <div>Unidade</div>
              </template>
              <template v-if="temSentido(coluna)">
                <div
                  style="width: 150px"
                  class="my-2"
                >
                  <input-calibracao
                    :ref="`inputOrdem${
                      buscarValor(linha, coluna.id).indiceTabulacao
                    }`"
                    v-model="buscarValor(linha, coluna.id).valor"
                    :prevent-tab="
                      buscarValor(linha, coluna.id).indiceTabulacao != undefined
                    "
                    class="flex-fill input-tabela"
                    :readonly="pontosSemInstrumento.length > 0"
                    :sistema="tabelaValoresAtual.unidadeMedidaSistema"
                    :disabled="bloquearCampos"
                    @focus="mostrarInstrumentoPadrao(linha, coluna.id)"
                    @tab="tabularInput(linha, coluna.id)"
                    @shit-tab="tabularInput(linha, coluna.id, true)"
                    @blur="calcularLinha(linha)"
                  />
                </div>
              </template>
              <template v-if="temRefencia(coluna)">
                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      text
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{
                        linha.referencia?.valor
                          | formatarNumeroComPrecisaoPodeVazio
                      }}
                    </v-btn>
                  </template>
                  <span>
                    Pontos:
                    {{
                      linha.referencia?.melhorCombinacao
                         | formataNumeroPontos
                    }}
                    <hr>
                    Residuo:
                    {{
                      linha.referencia?.residuo
                        | formatarNumeroComPrecisaoPodeVazio
                    }}
                  </span>
                </v-tooltip>
              </template>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-form>
    <v-divider class="my-5" />
    <div class="d-flex align-center justify-end">
      <botao-padrao
        outlined
        color="secondary"
        class="mr-2"
        @click="cancelar"
      >
        <v-icon class="mr-2">
          $mdiCancel
        </v-icon>
        {{ $t('geral.botoes.cancelar') }}
      </botao-padrao>
      <botao-padrao
        v-if="podeEditar"
        class="mr-2"
        :disabled="!alterado"
        @click="confirmacaoSalvar"
      >
        <v-icon class="mr-2">
          $mdiContentSaveOutline
        </v-icon>
        {{ $t('geral.botoes.salvar') }}
      </botao-padrao>
      <botao-padrao
        v-if="podeEditar"
        @click="confirmarFinalizarCalibracao"
      >
        {{ $t('modulos.calibracao.valores.botoes.finalizar') }}
      </botao-padrao>
    </div>
    <modal-instrumento-padrao
      ref="modalInstrumento"
      :valores="tabelaValoresAtual"
      :instrumento-id="instrumentoId"
      @salvar-tabela="salvarInstrumentosPadroes"
    />
    <modal-depuracao ref="modalDepuracao" />
    <OCR
      ref="ocr"
      @atualizar-status-ocr="atualizarStatusOCR"
      @texto-reconhecido="calcularLinhas()"
    />
    <linhas-com-erro-calculo-modal ref="modal-linhas-erros" />
  </div>
</template>
<script>
import { CalibracaoTabelaModel } from '@common/models/cadastros/CalibracaoTabelaModel';
import CalibracaoService from '@common/services/cadastros/CalibracaoService';
import helpers from '@common/utils/helpers';
import { InputCalibracao } from '@components/inputs';
import { SentidoConst } from '@const/SentidoConst';
import { TipoColunaConst } from '@const/TipoColunaConst';
import _ from 'lodash';
import ModalDepuracao from './ModalDepuracao';
import ModalInstrumentoPadrao from './ModalInstrumentoPadrao';
import OCR from './OCR';
import { MetodosBuscar, MetodosConfirmacao, MetodosSalvar } from './mixins';
import LinhasComErroCalculoModal from './modais/LinhasComErroCalculoModal.vue';
export default {
  components: {
    ModalInstrumentoPadrao,
    LinhasComErroCalculoModal,
    ModalDepuracao,
    OCR,
    InputCalibracao,
  },
  filters: {
    formatarResultado: function (v) {
      if (v.valor === '' || v.valor === null) return '';
      if (
        v.sistema != 'Sexagesimal' ||
        v.formula == 'veff' ||
        v.formula == 'fatork' ||
        v.formula == 'grausLiberdade'
      )
        return helpers.formatarNumeroComPrecisao(v.valor, true);
      return helpers.decimalParaAngulo(v.valor);
    },
    formataNumeroPontos:function(pontos) {
    if(!pontos || pontos.length == 0) return ''
      return  `[ ${pontos.map(p => p.toString().replace('.', ',')).join(' | ')} ]`
    }
  },
  mixins: [MetodosSalvar, MetodosBuscar, MetodosConfirmacao],
  props: [
    'calibracaoId',
    'tabelaSelecionada',
    'instrumentoId',
    'ultimaCalibracaoId',
    'ordemServicoId',
    'utilizaOcr',
    'podeCalibrar',
    'tabelasParaLinhasErro',
  ],
  data() {
    return {
      listaValores: [],
      instrumentoSelecionado: null,
      pontosInvalidos: [],
      statusOCR: false,
      linhasCalculadas: [],
      watchs: {
        tabelaSelecionada: null,
      },
      alterado: false,
      pontosSemInstrumento: [],
      bloquearCampos: false,
      keyTabelaValores: 0,
    };
  },
  computed: {
    instrumentoPadraoAtual: function () {
      if (
        !this.instrumentoSelecionado ||
        !this.instrumentoSelecionado.instrumento
      )
        return '';
      let faixa = this.instrumentoSelecionado.faixa;
      let formatarFaixa = `(${helpers.formatarNumeroComPrecisao(
        faixa.amplitudeMinima
      )} a ${helpers.formatarNumeroComPrecisao(
        faixa.amplitudeMaxima
      )}) ${faixa.unidadeMedida.nome} ; ${faixa.resolucao}`

      return `${this.instrumentoSelecionado.instrumento.codigoTag} - ${formatarFaixa}`;
    },
    tabelaValoresAtual: function () {
      let tabelaAtual = this.listaValores.find(
        (el) => el.tabelaId == this.tabelaSelecionada.id
      );
      if (!tabelaAtual) return new CalibracaoTabelaModel({});
      return tabelaAtual;
    },
    podeEditar: function () {
      return this.$route.name != 'calibracao-visualizar' && this.podeCalibrar;
    },
    colunas: function () {
      let colunas = [];
      let indiceTexto = 1;
      let tipo = '';
      this.tabelaValoresAtual.colunas.forEach((coluna) => {
        if (
          coluna.tipoColuna == TipoColunaConst.RESULTADO &&
          tipo != TipoColunaConst.RESULTADO
        )
          colunas.push({
            id: 'load',
            cabecalho: '',
            tipoColuna: TipoColunaConst.LOADING,
          });
        tipo = coluna.tipoColuna;
        if (coluna.tipoColuna == TipoColunaConst.TEXTO) {
          coluna.indiceTexto = `valorTexto${indiceTexto}`;
          indiceTexto++;
        }
        colunas.push(coluna);
      });
      return colunas;
    },
    temSMC: function () {
      return (
        this.tabelaValoresAtual.colunas.filter(
          (el) => el.tipoColuna == TipoColunaConst.SMC
        ).length > 0
      );
    },
    temSMP: function () {
      return (
        this.tabelaValoresAtual.colunas.filter(
          (el) => el.tipoColuna == TipoColunaConst.SMP
        ).length > 0
      );
    },
  },
  methods: {
    inicializarTela: async function () {
      await this.buscarListaTabelasCalibracao();
      await this.validarInstrumentos();
      this.chamarRedis();
      if (this.watchs.tabelaSelecionada) this.watchs.tabelaSelecionada();
      this.watchs.tabelaSelecionada = this.$watch(
        'tabelaSelecionada.id',
        async function () {
          await this.validarInstrumentos();
          setTimeout(async () => {
            this.verficarPontosSemInstrumentos();
            this.abrirModalInstrumentoVazio();
            this.$refs.inputOrdem1[0].focus();
          }, 200);
        }
      );

      setTimeout(async () => {
        this.verficarPontosSemInstrumentos();
        this.abrirModalInstrumentoVazio();
        this.$refs.inputOrdem1[0].focus();
      }, 500);
    },
    verficarPontosSemInstrumentos: function () {
      this.pontosSemInstrumento =
        this.tabelaValoresAtual.instrumentosPadroes.filter(
          (x) => !x.instrumento
        );
    },
    foiAlterado: function () {
      return this.alterado;
    },
    iniciarCapturaOCR: function () {
      this.$refs.ocr.iniciarCompartilhamento();
    },
    interrromperCapturaOCR: function () {
      this.$refs.ocr.finalizarCompartilhamento();
    },
    atualizarStatusOCR: function (statusOCR) {
      this.statusOCR = statusOCR;
    },
    abrirParaConversorDeUnidadeDeMedida: function () {
      window.open(
        this.$router.resolve({ name: 'conversor-unidade' }).href,
        '_blank'
      );
    },
    abrirEdicaoDoInstrumento: function () {
      window.open(
        this.$router.resolve({
          name: 'instrumento-editar',
          params: { id: this.instrumentoId },
        }).href,
        '_blank'
      );
    },
    abrirVisualizarDadosHistorico: function () {
      window.open(
        this.$router.resolve({
          name: 'calibracao-visualizar',
          params: {
            calibracaoId: this.ultimaCalibracaoId,
          },
        }).href,
        '_blank'
      );
    },

    limparColuna: function (tipoColuna) {
      this.tabelaValoresAtual.linhas.forEach((linha) => {
        linha.valores = linha.valores.map((colunaValor) => {
          if (colunaValor.tipoColuna == tipoColuna) colunaValor.valor = '';
          return colunaValor;
        });
        linha.resultados = [];
        this.keyTabelaValores++;
        this.calcularLinha(linha);
      });
    },
    copiarColuna: function (tipoColuna) {
      this.tabelaValoresAtual.linhas.forEach((linha) => {
        let valorAnterior = linha.pontoCalibracao.valor;
        linha.valores = linha.valores.map((colunaValor) => {
          if (colunaValor.tipoColuna == tipoColuna) {
            if (colunaValor.valor != '' && colunaValor.valor != null)
              valorAnterior = colunaValor.valor;
            colunaValor.valor = valorAnterior;
          }
          return colunaValor;
        });
        this.keyTabelaValores++;
        this.calcularLinha(linha);
      });
    },
    copiarHistorico: function () {
      this.$store.dispatch('Layout/iniciarCarregamento');
      CalibracaoService.buscarHistorico(
        this.tabelaSelecionada.id,
        this.instrumentoId
      )
        .then((res) => {
          res.data.forEach((linhaHistorico) => {
            let linha = this.tabelaValoresAtual.linhas.find(
              (linhaValores) =>
                linhaValores.pontoCalibracao.id ==
                  linhaHistorico.instrumentoFaixaPontoId &&
                linhaValores.tabelaLinhaId == linhaHistorico.tabelaLinhaId
            );

            if (linha) {
              linhaHistorico.valores.forEach((valorHistorico) => {
                let coluna = linha.valores.find(
                  (el) => el.tabelaColunaId == valorHistorico.tabelaColunaId
                );
                if (coluna) coluna.valor = valorHistorico.valor;
              });
              this.calcularLinha(linha);
            }
          });
          this.keyTabelaValores++;
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    abrirModalInstrumentoVazio: function (linha, tabelaColunaId) {
      if (this.pontosSemInstrumento.length > 0) {
        this.$refs[`inputOrdem1`][0].blur();
        if (linha && tabelaColunaId)
          this.$refs[
            `inputOrdem${
              this.buscarValor(linha, tabelaColunaId).indiceTabulacao
            }`
          ][0].blur();
        setTimeout(() => {
          this.$refs.modalInstrumento.abrirModalPersistente(
            this.pontosSemInstrumento.map((el) => {
              return {
                variavelId: el.formulaVariavelId,
                pontoCalibracaoId: el.pontoCalibracaoId,
              };
            })
          );
        }, 500);
      }
    },
    mostrarInstrumentoPadrao: function (linha, tabelaColunaId) {
      this.abrirModalInstrumentoVazio(linha, tabelaColunaId);
      let celulaSelecionada = this.buscarValor(linha, tabelaColunaId);
      // console.log(this.tabelaValoresAtual)
      this.instrumentoSelecionado =
        this.tabelaValoresAtual.instrumentosPadroes.find(
          (el) =>
            el.instrumentoFaixaPontoId == celulaSelecionada.pontoCalibracaoId &&
            el.formulaVariavel.id == celulaSelecionada.formulaVariavelId
        );

      console.log(this.instrumentoSelecionado, 'instrumentoSelecionado');
    },

    validarInstrumentos: async function () {
      if (!this.tabelaSelecionada.id || !this.podeEditar) return;
      this.$store.dispatch(
        'Layout/iniciarCarregamento',
        'Validando instrumentos da tabela de calibração'
      );
      await CalibracaoService.validarInstrumentos(
        this.calibracaoId,
        this.tabelaSelecionada.id
      )
        .then((res) => {
          if (res.data.length == 0) return;

          let mensagem = this.$t(
            'modulos.calibracao.valores.alertas.mensagem_instrumentos'
          );
          res.data.forEach((m) => {
            mensagem += `<br> - ${m.instrumento.nome} - ${this.$t(
              `enums.EnumInstrumentoPadraoValidacao.${m.instrumentoPadraoValidacao}`
            )}`;
          });
          this.mensagemConfirmacao(
            this.$t('geral.titulos.atencao'),
            mensagem,
            this.$t('geral.botoes.ok'),
            true
          );
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    validarPontos: function () {
      CalibracaoService.validarPontos(
        this.calibracaoId,
        this.tabelaSelecionada.id
      ).then((res) => {
        if (!res.data.isValid)
          this.toastErro(
            this.$t('modulos.calibracao.valores.pontos_invalidos')
          );

        this.pontosInvalidos = res.data.pontosForaFaixa;
      });
    },
    validarEstePonto: function (ponto) {
      return !this.pontosInvalidos.includes(ponto);
    },
    retornarIconeDirecao: function (direcao) {
      switch (direcao) {
        case SentidoConst.DeBaixoParaCima:
          return '$arrowUp';
        case SentidoConst.DeCimaParaBaixo:
          return '$arrowDown';
        case SentidoConst.DaEsquerdaParaDireita:
          return '$arrowRight';
        case SentidoConst.DeCimaParaBaixoProximaTabela:
          return '$cimaProxima';
        case SentidoConst.DeBaixoParaCimaProximaTabela:
          return '$baixoProxima';
      }
    },

    temSentido: function (coluna) {
      return (
        coluna.tipoColuna == TipoColunaConst.SMC ||
        coluna.tipoColuna == TipoColunaConst.SMP
      );
    },
    temRefencia: function (coluna) {
      return coluna.tipoColuna == TipoColunaConst.REFERENCIA;
    },
    ehLoading: function (coluna) {
      return coluna.tipoColuna == TipoColunaConst.LOADING;
    },
    ehResultado: function (coluna) {
      return coluna.tipoColuna == TipoColunaConst.RESULTADO;
    },
    temUnidade: function (coluna) {
      return coluna.tipoColuna == TipoColunaConst.UNIDADE;
    },
    temTexto: function (coluna) {
      return (
        coluna.tipoColuna == TipoColunaConst.TEXTO ||
        coluna.tipoColuna == TipoColunaConst.REFERENCIA
      );
    },
    buscarValor: function (linha, tabelaColunaId) {
      return linha.valores.find(
        (valor) => valor.tabelaColunaId == tabelaColunaId
      );
    },
    tabularInput: function (linha, colunaId, shift = false) {
      let input = this.buscarValor(linha, colunaId);
      if (input.indiceTabulacao == undefined) return;
      if (this.statusOCR)
        this.$refs.ocr.reconhecerTexto(
          this.$refs[`inputOrdem${input.indiceTabulacao}`][0],
          input
        );
      if (input.ultimo && !input.proximaTabela && !shift) {
        this.confirmacaoIrProximaTabela();
        return;
      }
      let indiceAtual = this.listaValores.indexOf(
        this.listaValores.find((el) => el.tabelaId == this.tabelaSelecionada.id)
      );
      if (input.proximaTabela && !shift && this.listaValores.length > 1) {
        indiceAtual++;
        this.$refs[`inputOrdem${input.indiceTabulacao}`][0].blur();
        setTimeout(() => {
          if (indiceAtual == this.listaValores.length) indiceAtual = 0;
          this.tabelaSelecionada.id = this.listaValores[indiceAtual].tabelaId;
          this.focarPrimeiroCampoVazio();
        }, 200);
        return;
      }
      if (input.voltarTabela && !shift && this.listaValores.length > 1) {
        indiceAtual--;
        this.$refs[`inputOrdem${input.indiceTabulacao}`][0].blur();
        setTimeout(() => {
          if (indiceAtual < 0) indiceAtual = this.listaValores.length - 1;
          this.tabelaSelecionada.id = this.listaValores[indiceAtual].tabelaId;
          this.focarPrimeiroCampoVazio();
        }, 200);
        return;
      }
      if (shift && input.indiceTabulacao == 1) return;
      this.$refs[
        `inputOrdem${input.indiceTabulacao + (!shift ? 1 : -1)}`
      ][0].focus();
    },
    focarPrimeiroCampoVazio: function () {
      setTimeout(() => {
        let indice = 1;
        let achou = false;
        while (this.$refs[`inputOrdem${indice}`] !== undefined) {
          if (!this.$refs[`inputOrdem${indice}`][0].value) {
            achou = true;
            break;
          }
          indice++;
        }
        if (achou) this.$refs[`inputOrdem${indice}`][0].focus();
        else this.$refs[`inputOrdem${indice - 1}`][0].focus();
      }, 400);
    },
    calcularLinhas: function () {
      this.tabelaValoresAtual.linhas
        .filter((linha) => {
          return linha.valores.every(
            (el) => el.valor != '' && el.valor != null
          );
        })
        .forEach((linha) => {
          this.calcularLinha(linha);
        });
    },
    calcularLinha: function (linha) {
      let valoresFiltrados = linha.valores.filter(
        (el) =>
          el.tipoColuna == TipoColunaConst.SMC ||
          el.tipoColuna == TipoColunaConst.SMP
      );

      let linhaCalculada = this.linhasCalculadas.find(
        (el) => el.linhaId == linha.id
      );
      let valido = !linhaCalculada;
      if (linhaCalculada) {
        valoresFiltrados.forEach((el) => {
          if (
            !linhaCalculada.valores.find(
              (l) =>
                l.tabelaColunaId == el.tabelaColunaId && l.valor === el.valor
            )
          ) {
            valido = true;
            return;
          }
        });
      }

      if (valido) this.alterado = true;

      linhaCalculada.valores = valoresFiltrados.map((el) => {
        return {
          tabelaColunaId: el.tabelaColunaId,
          valor: _.cloneDeep(el.valor),
        };
      });

      if (
        !valoresFiltrados.every((el) => el.valor !== '' && el.valor !== null)
      ) {
        linha.resultados = [];
        return;
      }

      if (valido) {
        linha.resultados = [];
        linha.calculando = true;
        this.$forceUpdate();
        CalibracaoService.calcularLinha(this.calibracaoId, linha.id, linha)
          .then((res) => {
            linha.resultados = res.data.valores;
          })
          .finally(() => {
            linha.calculando = false;
            this.$forceUpdate();
          });
      }
    },
    depurarLinha: function (linha) {
      this.$refs['modalDepuracao'].abrirModal(this.calibracaoId, linha);
    },
    buscarResultado: function (linha, colunaId) {
      let resultado = linha.resultados.find((el) => el.id == colunaId)?.valor;
      if (resultado === undefined) return '';
      return String(resultado).replace('.', ',');
    },
    cancelar: function () {
      this.confirmarCancelar().then(() => {
        this.$router.push({
          name: this.$route.query?.retorno || 'operacao',
          params: { id: this.$route.query?.instrumentoRetorno || null },
        });
      });
    },
  },
};
</script>
<style lang="scss">
.coluna-select {
  width: 200px;
  font-weight: 400;
}
.tabela-dinamica-calibracao {
  th {
    background-color: $color-primary-grey-100;
  }
  .coluna-propriedades {
    width: 0;
  }
  .linhas tr:first-child td {
    border-top: 2px solid;
  }
}
</style>
