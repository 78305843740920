<template>
  <div>
    <div class="mt-3 d-flex align-center justify-space-between">
      <div />
      <input-select
        v-model="faixaSelecionada"
        class="w-35"
        :label="$t('modulos.calibracao.formulario.abas.faixas')"
        :placeholder="$t('geral.inputs.selecione')"
        :options="faixas"
        em-filtro
        :clearable="false"
      />
    </div>
    <v-alert
      v-if="instrumentoPadrao"
      type="warning"
      dense
      class="mt-2"
    >
      {{ $t('modulos.calibracao.tabela_calibracao.instrumento_padrao') }}
    </v-alert>
    <v-alert
      v-if="camposAdicionaisRestrito"
      type="warning"
      dense
      class="mt-2"
    >
      {{ $t('modulos.calibracao.tabela_calibracao.campo_adicional_restrito') }}
    </v-alert>
    <data-table
      v-if="tabelasSelecionadasFiltrada?.selecionados"
      :value="tabela.dados"
      class="p-datatable-sm tabela-prime-vue-inherit mt-2"
      :disabled="!podeEditar"
      :expanded-rows.sync="expandedRows"
      show-select
      show-gridlines
      striped-rows
      data-key="id"
      :row-hover="true"
      responsive-layout="scroll"
      :selection.sync="tabelasSelecionadasFiltrada.selecionados"
    >
      <Column :expander="true">
        <template #body="{data}">
          <span v-if="data.tabelaAuxiliar != null">
            <button
              @click="toggleRow(data)"
            >
              <v-icon :class="{'rotated': expandedRows.find(i => i.id == data.id), 'not-rotated': !expandedRows.find(i => i.id == data.id), 'icon-margin': true}">$downArrow</v-icon>            </button>
          </span>
        </template>
      </Column>
      <Column
        selection-mode="multiple"
        :styles="{'min-width': '3rem'}"
      />
      <Column
        v-for="(col, index) of colunasSelecionadas"
        :key="col.field + '_' + index"
        :field="col.field"
        :header="col.header"
      >
        <template #body="slotProps">
          <slot
            v-if="col.field == 'procedimentoEletronico'"
            :slotProps="slotProps"
            :name="col.field"
          >
            {{ slotProps.data.procedimentoEletronico.codigo
            }}{{ slotProps.data.procedimentoEletronico.revisao ? `.${slotProps.data.procedimentoEletronico.revisao}` : '' }} -
            {{ slotProps.data.procedimentoEletronico.nome }}
          </slot>
          <slot
            v-if="col.field == 'nome'"
            :slotProps="slotProps"
            :name="col.field"
          >
            {{ slotProps.data.nome }}
          </slot>
          <slot
            v-if="col.field == 'faixa'"
            :slotProps="slotProps"
            :name="col.field"
          >
            {{ `(${slotProps.data.amplitudeMinima} a ${slotProps.data.amplitudeMaxima}) ${slotProps.data.unidadeMedida.nome}` }}
          </slot>
          <slot
            v-if="col.field == 'pontosCalibracao'"
            :slotProps="slotProps"
            :name="col.field"
          >
            {{ col.formatter(slotProps.data.pontosCalibracao) }}
          </slot>
          <icone-padrao
            v-if="!slotProps.data.allPontosHasEscopoFaixa"
            icone="$alertWarningtOutline"
            cor="red"
            :tooltip="$t('modulos.calibracao.formulario.alerta_escopo')"
          />
        </template>
      </Column>
      <template #expansion="{data}">
        <div class="orders-subtable">
          <DataTable
            striped-rows
            edit-mode="row"
            class="tabela-prime-vue-inherit tabela-instrumento"
            :value="dadosTabelaAuxiliar(data.tabelaAuxiliar)"
          >
            <Column
              v-for="(col, index) of colunasAuxiliaresSelecionadas"
              :key="col.field + '_' + index"
              :field="col.field"
              :header="col.header"
              :styles="{ width: '1%' }"
            >
              <template #body="slotProps">
                <div class="d-flex">
                  <slot
                    v-if="col.field == 'procedimentoEletronico'"
                    :slotProps="slotProps"
                    :name="col.field"
                  >
                    {{ slotProps.data?.procedimentoEletronico?.codigo
                    }}{{ slotProps.data?.procedimentoEletronico?.revisao ? `.${slotProps.data?.procedimentoEletronico?.revisao}` : '' }} -
                    {{ slotProps.data?.procedimentoEletronico?.nome }}
                  </slot>
                  <slot
                    v-if="col.field == 'nome'"
                    :slotProps="slotProps"
                    :name="col.field"
                  >
                    {{ slotProps.data?.nome }}
                  </slot>
                  <slot
                    v-if="col.field == 'faixa'"
                    :slotProps="slotProps"
                    :name="col.field"
                  >
                    {{ faixaFormatada(`(${slotProps.data?.amplitudeMinima} a ${slotProps.data?.amplitudeMaxima}) ${slotProps.data?.unidadeMedida?.nome}`) }}
                  </slot>
                  <slot
                    v-if="col.field == 'pontosCalibracao'"
                    :slotProps="slotProps"
                    :name="col.field"
                  >
                    {{ col.formatter(slotProps.data?.pontosCalibracao) }}
                  </slot>
                </div>
              </template>
            </Column>
          </DataTable>
        </div>
      </template>
    </data-table>
    <v-divider class="my-5" />
    <div class="d-flex align-center justify-end">
      <botao-padrao
        outlined
        color="secondary"
        class="mr-2"
        @click="cancelar"
      >
        <v-icon class="mr-2">
          $mdiCancel
        </v-icon>
        {{ $t('geral.botoes.cancelar') }}
      </botao-padrao>
      <botao-padrao
        v-if="podeEditar"
        @click="confirmacaoSalvar"
      >
        {{ $t('geral.botoes.salvar') }}
      </botao-padrao>
    </div>
  </div>
</template>
<script>
import ProcedimentoEletronicoService from '@common/services/cadastros/ProcedimentoEletronicoService';
import CalibracaoService from '@common/services/cadastros/CalibracaoService';
import _ from 'lodash';
import helpers from '@/common/utils/helpers';
export default {
  props: {
    calibracaoId: {},
    tabelas: { default: () => [] },
    faixas: {},
    podeCalibrar: {},
    instrumentoPadrao: {
      type: Boolean,
      default: false,
    },
    calibracaoInexistente: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      faixaSelecionada: null,
      camposAdicionaisRestrito: false,
      watchTabelas: null,
      alterado: this.calibracaoInexistente,
      tabelaAlteradosClone: null,
      expandedRows: [],
      colunasSelecionadas: [],
      colunasAuxiliaresSelecionadas: [],
      tabela: {
        selecionados: [],
        dados: [],
        colunas: [
          {
            value: 'procedimentoEletronico',
            text: this.$t('modulos.calibracao.tabela_calibracao.procedimento'),
            sortable: false,
            formatter: (valor) => {
              if (valor) return `${valor.codigo} - ${valor.nome}`;
            },
          },
          {
            value: 'nome',
            text: this.$t('modulos.calibracao.tabela_calibracao.tabela'),
            sortable: false,
          },
          {
            value: 'faixa',
            text: this.$t('modulos.calibracao.tabela_calibracao.faixa'),
            sortable: false,
            formatter: (valor, linha) => {
              if (linha && linha.unidadeMedida)
                return `${helpers.formatarNumeroComPrecisao(
                  linha.amplitudeMinima
                )} a ${helpers.formatarNumeroComPrecisao(
                  linha.amplitudeMaxima
                )} ${linha.unidadeMedida.nome}`;
            },
          },
          {
            value: 'pontosCalibracao',
            text: this.$t('modulos.calibracao.tabela_calibracao.pontos'),
            sortable: false,
            formatter: (valor) => {
              if (valor)
                return valor
                  .filter((el) => el.faixaId == this.faixaSelecionada)
                  .map((el) => helpers.formatarNumeroComPrecisao(el.ponto))
                  .join(' ; ');
            },
          },
        ],
      },
      colunasAuxiliares: [
        {
            value: 'procedimentoEletronico',
            text: this.$t('modulos.calibracao.tabela_calibracao.procedimento'),
            sortable: false,
            formatter: (valor) => {
              if (valor) return `${valor.codigo} - ${valor.nome}`;
            },
          },
          {
            value: 'nome',
            text: this.$t('modulos.calibracao.tabela_calibracao.tabela_auxiliar'),
            sortable: false,
          },
          {
            value: 'faixa',
            text: this.$t('modulos.calibracao.tabela_calibracao.faixa'),
            sortable: false,
            formatter: (valor, linha) => {
              if (linha && linha.unidadeMedida)
                return `${helpers.formatarNumeroComPrecisao(
                  linha.amplitudeMinima
                )} a ${helpers.formatarNumeroComPrecisao(
                  linha.amplitudeMaxima
                )} ${linha.unidadeMedida.nome}`;
            },
          },
          {
            value: 'pontosCalibracao',
            text: this.$t('modulos.calibracao.tabela_calibracao.pontos'),
            sortable: false,
            formatter: (valor) => {
              if (valor)
                return valor
                  .filter((el) => el.faixaId == this.faixaSelecionada)
                  .map((el) => helpers.formatarNumeroComPrecisao(el.ponto))
                  .join(' ; ');
            },
          },
      ]
    };
  },
  computed: {
    tooltipBotaoSalvar: function () {
      if (this.valido) return '';
      return this.$t('modulos.calibracao.tabela_calibracao.selecione_linha');
    },
    valido: function () {
      return this.tabela.selecionados.length > 0;
    },
    podeEditar: function () {
      return this.$route.name != 'calibracao-visualizar' && this.podeCalibrar;
    },
    tabelasSelecionadasFiltrada: function () {
      return this.tabela.selecionados.find(
        (el) => el.faixaId == this.faixaSelecionada
      );
    },
  },
  watch: {
    faixaSelecionada: function () {
      if (!this.faixaSelecionada) return;
      if (
        !this.tabela.selecionados.find(
          (el) => el.faixaId == this.faixaSelecionada
        )
      )
        this.tabela.selecionados.push({
          faixaId: _.cloneDeep(this.faixaSelecionada),
          selecionados: [],
        });
    },
    tabelas: {
      handler() {
        const chaveUnica = new Set();
        const novoArray = [];
        if (
          (!this.tabelas ? true : this.tabelas.length == 0) &&
          this.tabela.selecionados.length > 0
        )
          return;

        for (const faixa of this.tabelas) {
          for (const selecionado of faixa.faixasIds) {
            if (!chaveUnica.has(selecionado)) {
              chaveUnica.add(selecionado);
              novoArray.push(selecionado);
            }
          }
        }

        this.tabela.selecionados = novoArray.map((n) => {
          return {
            faixaId: n,
            selecionados: this.tabelas
              .filter((el) => el.faixasIds.find((x) => x == n))
              .map((el) => {
                return {
                  id: el.identificadorTabela,
                  procedimentoEletronico: el.procedimentoEletronico,
                };
              }),
          };
        });

        this.faixas.forEach((faixa) => {
          if (
            !this.tabela.selecionados.find((el) => el.faixaId == faixa.value)
          ) {
            this.tabela.selecionados.push({
              faixaId: _.cloneDeep(faixa.value),
              selecionados: [],
            });
          }
        });

        this.buscarTabelasProcedimento();
      },
      immediate: true,
    },
  },
  mounted() {
    this.tabela.colunas?.forEach((coluna) => {
      coluna.field = coluna.value;
      coluna.header = coluna.text;
    });
    this.colunasSelecionadas = this.tabela.colunas

    this.colunasAuxiliares?.forEach((coluna) => {
      coluna.field = coluna.value;
      coluna.header = coluna.text;
    });
    this.colunasAuxiliaresSelecionadas = this.colunasAuxiliares
  },
  methods: {
    validar: function () {
      return this.tabela.selecionados.length > 0;
    },
    foiAlterado: function () {
      return this.alterado;
    },
    toggleRow(rowData) {
      const index = this.expandedRows.findIndex(row => row.id === rowData.id);
      if (index === -1) {
        this.expandedRows.push(rowData)
      } else {
        this.expandedRows.splice(index, 1)
      }
    },
    dadosTabelaAuxiliar: function (data) {
      return [{...data}];
    },
    podeSelecionar: function (item) {
      const chaveUnica = new Set();
      const novoArray = [];

      for (const faixa of this.tabela.selecionados) {
        for (const selecionado of faixa.selecionados) {
          if (!chaveUnica.has(selecionado.id)) {
            chaveUnica.add(selecionado.id);
            novoArray.push(selecionado);
          }
        }
      }

      if (!item.allPontosHasEscopoFaixa || !item.isUsuarioLogadoExecutante) {
        return false;
      }

      if (novoArray.length == 0) return true;

      const selecionados = this.tabela.selecionados.find(
        (el) => el.faixaId == this.faixaSelecionada
      ).selecionados;
      if (this.instrumentoPadrao && selecionados.length > 0) {
        return item.id == selecionados[0].id;
      }

      return (
        item.procedimentoEletronico.id == novoArray[0].procedimentoEletronico.id
      );
      // return true
    },
    buscarTabelasProcedimento: async function () {
      if (this.watchTabelas) this.watchTabelas();
      let selecionados = [];
      this.$store.dispatch('Layout/iniciarCarregamento');
      await ProcedimentoEletronicoService.buscarTabelasCalibracao(
        this.calibracaoId
      )
        .then((res) => {
          this.tabela.dados = res.data.tabelas.map((el) => {
            el.id = el.identificadoTabela;
            return el;
          });
          this.camposAdicionaisRestrito =
            res.data.hasRestrictiveCamposAdicionais;

          let tabelas = this.tabela.dados.filter(
            (el) => el.allPontosHasEscopoFaixa
          );

          if (this.tabelas.length == 0 && tabelas.length > 0) {
            const primeiraTabelaQuePodeSelecionar = tabelas.find(
              (tabela) =>
                tabela.allPontosHasEscopoFaixa &&
                tabela.isUsuarioLogadoExecutante
            );
            if (primeiraTabelaQuePodeSelecionar) {
              let procedimentoId =
                primeiraTabelaQuePodeSelecionar.procedimentoEletronico.id;
              if (
                tabelas.every(
                  (el) => el.procedimentoEletronico.id == procedimentoId
                )
              ) {
                selecionados = _.cloneDeep(tabelas);
              } else {
                let primeiroProcedimentoId =
                  primeiraTabelaQuePodeSelecionar.procedimentoEletronico.id;
                selecionados = _.cloneDeep(
                  tabelas.filter(
                    (el) =>
                      el.procedimentoEletronico.id == primeiroProcedimentoId
                  )
                );
              }
            }
          }

          this.watchTabelas = this.$watch(
            'tabela.selecionados',
            function (n, o) {
              if (
                !_.isEqual(this.tabelaAlteradosClone, o) &&
                this.tabelaAlteradosClone != null
              ) {
                this.alterado = true;
              }
              this.tabelaAlteradosClone = _.cloneDeep(this.tabela.selecionados);
            },
            { deep: true }
          );
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
          if (!this.faixaSelecionada && this.faixas.length) {
            if (selecionados.length > 0) {
              this.tabelaAlteradosClone = [];
              this.faixas.forEach((faixa) => {
                this.tabela.selecionados.find(
                  (el) => el.faixaId == faixa.value
                ).selecionados = this.instrumentoPadrao
                  ? [selecionados[0]]
                  : selecionados;
              });
            }
            this.faixaSelecionada = this.faixas[0].value;
          }

          this.tabelaAlteradosClone = _.cloneDeep(this.tabela.selecionados);
        });
    },
    confirmacaoSalvar: function () {
      if (this.id) {
        this.confirmarSalvar().then(() => {
          this.salvar();
        });
      } else this.salvar();
    },
    salvar: function (proximaAba = 1) {
      const chaveUnica = new Set();
      const novoArray = [];

      for (const faixa of this.tabela.selecionados) {
        for (const selecionado of faixa.selecionados) {
          if (!chaveUnica.has(selecionado.id)) {
            chaveUnica.add(selecionado.id);
            novoArray.push(selecionado);
          }
        }
      }

      const payload = novoArray.map((n) => {
        return {
          tabelaId: n.id,
          faixasIds: this.tabela.selecionados
            .filter((el) => el.selecionados.find((x) => x.id == n.id))
            .map((el) => el.faixaId),
        };
      });

      if (payload.length == 0) {
        this.$emit('buscar-tabelas', proximaAba);
        return;
      }
      this.eventoSalvar(payload, proximaAba);
    },
    eventoSalvar: function (payload, proximaAba) {
      this.$store.dispatch('Layout/iniciarCarregamento');
      CalibracaoService.salvarTabelas(this.calibracaoId, payload)
        .then(() => {
          this.alterado = false;
          this.toastSucesso(
            this.$t(`modulos.calibracao.tabela_calibracao.cadastro_sucesso`)
          );
          this.$emit('buscar-tabelas', proximaAba);
        })
        .catch(() => {
          this.$emit('voltar-aba');
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    cancelar: function () {
      this.confirmarCancelar().then(() => {
        this.$router.push({
          name: this.$route.query?.retorno || 'operacao',
          params: { id: this.$route.query?.instrumentoRetorno || null },
        });
      });
    },
    faixaFormatada: function (value) {
      return String(value).replaceAll('.', ',');
    }
  },
};
</script>
<style lang="scss">
.rotated {
  transform: rotate(180deg);
  transition: transform 0.3s ease;
}

.not-rotated {
  transform: rotate(0deg);
  transition: transform 0.3s ease;
}


.p-datatable.p-datatable-sm .p-datatable-tbody > tr > td {
  padding: 0.4rem !important;
}

.tabela-prime-vue-inherit {
  font-size: inherit;
  font-family: inherit;
  font-weight: inherit;
}

.icon-margin {
  margin-left: 0.005rem;
}

.p-datatable .p-column-header-content .p-checkbox .p-component{
  display: none;
}

.orders-subtable {
  padding: 0 0 16px 145px !important;
  .p-datatable-wrapper {
    table {
      thead {
        th {
          background-color: lighten(#35507b, 50%) !important;
          border-left: 4px solid lighten(#35507b, 50%) !important;
          border-right: 4px solid lighten(#35507b, 50%) !important;
        }
      }
      tbody {
        tr {
          td:first-child {
            border-left: 4px solid lighten(#35507b, 50%) !important;
          }
          td:last-child {
            border-right: 4px solid lighten(#35507b, 50%) !important;
          }
        }
        tr:last-child {
          td {
            border-bottom: 4px solid lighten(#35507b, 50%) !important;
          }
        }
      }
    }
  }
}
</style>
