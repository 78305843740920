<template>
  <div class="mt-3">
    <v-form
      ref="form"
      :disabled="!podeEditar"
    >
      <div class="row">
        <input-text
          id="dados-codigo"
          v-model="form.codigoCertificado"
          class="col-12 col-md-4"
          :label="$t('modulos.calibracao.formulario.codigo_certificado')"
          disabled
        />
        <input-date
          id="dados-data"
          v-model="form.dataCalibracao"
          class="col-12 col-md-4"
          :label="$t('modulos.calibracao.formulario.data')"
          obrigatorio
          apenas-datas-passadas
        />
        <input-select
          id="dados-tipo-calibracao"
          v-model="form.tipoCalibracaoId"
          class="col-12 col-md-4"
          :label="$t('modulos.calibracao.formulario.tipo_calibracao')"
          :options="opcoes.tipoCalibracao"
          :disabled="desabilitaTipoCalibracao"
          obrigatorio
        />
        <input-select
          id="dados-local-execucao"
          v-model="form.localExecucaoId"
          class="col-12 col-md-6"
          :label="$t('modulos.calibracao.formulario.local_execucao')"
          :options="opcoes.localExecucao"
          :disabled="desabilitaLocalExecucao"
          obrigatorio
        />
        <input-tipo-instrumento-tecnicos-executantes
          v-model="form.usuarioTecnicoExecutanteId"
          class="col-12 col-md-6"
          :label="$t('modulos.calibracao.formulario.usuarioTecnicoResponsavel')"
          :titulo-modal="
            $t('modulos.calibracao.titulos.usuarioTecnicoResponsavel')
          "
          :multiplos="false"
          :tipo-instrumento-id="tipoinstrumentoId"
        />
      </div>
      <accordion-padrao :titulo="$t('modulos.calibracao.titulos.temperatura')">
        <div class="row">
          <input-text
            v-model="form.temperaturaInicio"
            class="col-12 col-md-4"
            :label="$t('modulos.calibracao.formulario.inicio_c')"
            type="number"
            obrigatorio
          />
          <input-text
            v-model="form.temperaturaFim"
            class="col-12 col-md-4"
            :label="$t('modulos.calibracao.formulario.fim_c')"
            type="number"
            obrigatorio
          />
          <input-text
            v-model="form.temperaturaVariacao"
            class="col-12 col-md-4"
            :label="$t('modulos.calibracao.formulario.mais_menos_c')"
            type="number"
          />
        </div>
      </accordion-padrao>
      <accordion-padrao :titulo="$t('modulos.calibracao.titulos.umidade')">
        <div class="row">
          <input-text
            v-model="form.umidadeInicio"
            class="col-12 col-md-4"
            :label="$t('modulos.calibracao.formulario.inicio_porcentagem')"
            type="number"
            obrigatorio
          />
          <input-text
            v-model="form.umidadeFim"
            class="col-12 col-md-4"
            :label="$t('modulos.calibracao.formulario.fim_porcentagem')"
            type="number"
            obrigatorio
          />
          <input-text
            v-model="form.umidadeVariacao"
            class="col-12 col-md-4"
            :label="$t('modulos.calibracao.formulario.mais_menos_porcentagem')"
            type="number"
          />
        </div>
      </accordion-padrao>
      <accordion-padrao :titulo="$t('modulos.calibracao.titulos.pressao')">
        <div class="row">
          <input-text
            v-model="form.pressaoInicio"
            class="col-12 col-md-4"
            :label="$t('modulos.calibracao.formulario.inicio_pressao')"
            type="number"
          />
          <input-text
            v-model="form.pressaoFim"
            class="col-12 col-md-4"
            :label="$t('modulos.calibracao.formulario.fim_pressao')"
            type="number"
          />
          <input-text
            v-model="form.pressaoVariacao"
            class="col-12 col-md-4"
            :label="$t('modulos.calibracao.formulario.mais_menos_pressao')"
            type="number"
          />
        </div>
      </accordion-padrao>
      <div class="row mt-3">
        <input-text
          v-model="form.observacao"
          class="col-12"
          :label="$t('modulos.calibracao.formulario.observacao')"
        />
      </div>
    </v-form>
    <v-divider class="my-5" />
    <div class="d-flex align-center justify-end">
      <botao-padrao
        outlined
        color="secondary"
        class="mr-2"
        @click="cancelar"
      >
        <v-icon class="mr-2">
          $mdiCancel
        </v-icon>
        {{ $t('geral.botoes.cancelar') }}
      </botao-padrao>
      <botao-padrao
        v-if="podeEditar"
        :disabled="!valido"
        :tooltip="tooltipBotaoSalvar"
        @click="confirmacaoSalvar"
      >
        {{ $t('geral.botoes.salvar') }}
      </botao-padrao>
    </div>
  </div>
</template>
<script>
import { InputTipoInstrumentoTecnicosExecutantes } from '@/components/inputs';
import { DropdownModel } from '@common/models/geral/DropdownModel';

import {
  TipoCalibracaoService,
  LocalExecucaoService,
  CalibracaoService,
  InstrumentoService,
} from '@common/services';

export default {
  components: {
    InputTipoInstrumentoTecnicosExecutantes,
  },
  props: [
    'ordemServicoId',
    'instrumentoId',
    'instrumentoAnaliseTecnicaId',
    'form',
    'areaAcreditacaoId',
    'podeCalibrar',
    'tipoinstrumentoId',
  ],
  data() {
    return {
      valido: true,
      desabilitaTipoCalibracao: false,
      desabilitaLocalExecucao: false,
      opcoes: {
        tipoCalibracao: [],
        localExecucao: [],
      },
    };
  },
  computed: {
    tooltipBotaoSalvar: function () {
      if (this.valido) return '';
      return this.$t('geral.tips.formulario_invalido');
    },

    podeEditar: function () {
      return this.$route.name != 'calibracao-visualizar' && this.podeCalibrar;
    },
  },
  watch: {
    form: {
      handler() {
        if (this.$refs.form) this.valido = this.$refs.form.validate();
      },
      deep: true,
    },
  },
  mounted() {
    this.buscarTipoCalibracao();
    this.buscarLocalExecucao();
  },

  methods: {
    validar: function () {
      return this.$refs.form.validate();
    },
    buscarTipoCalibracao: function () {
      TipoCalibracaoService.listar().then((res) => {
        this.opcoes.tipoCalibracao = new DropdownModel(res.data);
      });
    },
    buscarLocalExecucao: function () {
      LocalExecucaoService.listar().then((res) => {
        this.opcoes.localExecucao = new DropdownModel(res.data);
      });
    },
    buscarTipoCalibracaoLocalExecucaoAnaliseTecnica: async function () {
      await InstrumentoService.buscarAnaliseTecnicaPorOrdemServico(
        this.instrumentoAnaliseTecnicaId || this.instrumentoId,
        this.ordemServicoId
      ).then((res) => {
        if (res.data.tipoCalibracao?.id) {
          this.desabilitaTipoCalibracao = true;
          this.form.tipoCalibracaoId = res.data.tipoCalibracao?.id;
        }
        if (res.data.localExecucao?.id) {
          this.desabilitaLocalExecucao = true;
          this.form.localExecucaoId = res.data.localExecucao?.id;
        }
      });
    },

    confirmacaoSalvar: function () {
      if (this.id) {
        this.confirmarSalvar().then(() => {
          this.salvar();
        });
      } else this.salvar();
    },

    salvar: function (proximaAba = 1) {
      this.$store.dispatch(
        'Layout/iniciarCarregamento',
        'Salvando dados da calibração'
      );

      CalibracaoService.salvar(this.form)
        .then(() => {
          this.toastSucesso(
            this.$t(`modulos.calibracao.dados.cadastro_sucesso`)
          );
          this.$emit('buscar-calibracao', proximaAba);
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    cancelar: function () {
      this.confirmarCancelar().then(() => {
        this.$router.push({
          name: this.$route.query?.retorno || 'operacao',
          params: { id: this.$route.query?.instrumentoRetorno || null},
        });
      });
    },
  },
};
</script>
