<template>
  <div>
    <div class="d-flex justify-space-between mb-8">
      <div class="titulo-pagina">
        <div>
          {{ tituloPagina }}
        </div>
        <div>
          <v-chip
            class="mr-1"
            small
          >
            Instrumento: {{ badges.codigo }}
          </v-chip>
          <v-chip
            class="mx-1"
            small
          >
            Tipo: {{ badges.tipo }}
          </v-chip>
          <v-chip
            class="ml-1"
            small
          >
            Ordem Serviço: {{ badges.os }}
          </v-chip>
          <v-chip
            v-if="badges.certificadoSubstituido"
            class="ml-1"
            small
            color="#35507b"
            text-color="white"
          >
            Certificado Substituido: {{ badges.certificadoSubstituido }}
          </v-chip>
        </div>
      </div>
      <!-- Tomar cuidado com indice-->
      <input-select
        v-if="indiceAba == 2"
        v-model="tabelaSelecionada.id"
        class="w-35"
        :label="$t('modulos.calibracao.formulario.abas.tabelas')"
        :placeholder="$t('geral.inputs.selecione')"
        :options="opcoesTabelas"
        em-filtro
        :clearable="false"
      />
    </div>
    <v-tabs
      v-model="indiceAba"
      class="abas-padrao"
      background-color="primary"
      color="primary"
      @change="salvarAoMudar"
    >
      <v-tab>
        {{ $t('modulos.calibracao.formulario.abas.dados') }}
      </v-tab>
      <v-tab>
        {{ $t('modulos.calibracao.formulario.abas.tabelas') }}
      </v-tab>
      <v-tab>
        {{ $t('modulos.calibracao.formulario.abas.valores') }}
      </v-tab>
      <v-tab>
        {{ $t('modulos.calibracao.formulario.abas.anexos') }}
      </v-tab>
      <v-tab>
        {{ $t('modulos.calibracao.formulario.abas.requisitos') }}
      </v-tab>
      <v-tab v-if="exibirDerivas">
        {{ $t('modulos.calibracao.formulario.abas.derivas') }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="indiceAbaAtual">
      <v-tab-item>
        <dados
          ref="dados"
          :form="calibracao"
          :ordem-servico-id="ordemServicoId"
          :instrumento-analise-tecnica-id="instrumentoAnaliseTecnicaId"
          :instrumento-id="calibracao.instrumentoId"
          :area-acreditacao-id="areaAcreditacaoId"
          :pode-calibrar="calibracao.podeCalibrar"
          :tipoinstrumento-id="
            instrumento?.tipoInstrumento?.value ?? badges.tipoId
          "
          @buscar-calibracao="continuarDados"
        />
      </v-tab-item>
      <v-tab-item>
        <tabela
          ref="tabelas"
          :calibracao-id="calibracao.id"
          :tabelas="tabelas"
          :pode-calibrar="calibracao.podeCalibrar"
          :faixas="faixas"
          :instrumento-padrao="instrumento.flagPadrao"
          :calibracao-inexistente="calibracaoInexistente"
          @buscar-tabelas="continuarTabelas"
          @voltar-aba="voltarAba"
        />
      </v-tab-item>
      <v-tab-item eager>
        <valores
          ref="valores"
          :ultima-calibracao-id="calibracao.ultimaCalibracaoId"
          :ordem-servico-id="ordemServicoId"
          :calibracao-id="calibracao.id"
          :tabela-selecionada="tabelaSelecionada"
          :instrumento-id="calibracao.instrumentoId"
          :utiliza-ocr="calibracao.flagUtilizaLeitorOCR"
          :pode-calibrar="calibracao.podeCalibrar"
          :tabelas-para-linhas-erro="tabelas"
          @proxima-tabela="editarProximaTabela"
        />
      </v-tab-item>
      <v-tab-item>
        <anexos
          :calibracao-id="calibracao.id"
          :pode-calibrar="calibracao.podeCalibrar"
        />
      </v-tab-item>
      <v-tab-item>
        <requisitos :requisitos-cliente="dadosRequisitosClienteFormatado" />
      </v-tab-item>
      <v-tab-item>
        <derivas
          :calibracao-id="calibracao.id"
          :instrumento-id="instrumento.id"
          :visualizacao="true"
        />
      </v-tab-item>
    </v-tabs-items>
    <modal-requisitos
      ref="modalRequisitos"
      :requisitos-cliente="dadosRequisitosClienteFormatado"
    />
  </div>
</template>
<script>
import {
  CalibracaoService,
  ParticipanteService,
  OrdemServicoService,
  InstrumentoService,
  LocalExecucaoService,
} from '@common/services';

import { CalibracaoModel } from '@common/models/cadastros/CalibracaoModel';
import { OrdemServicoModel } from '@common/models/cadastros/OrdemServicoModel';
import { InstrumentoModel } from '@common/models/cadastros/InstrumentoModel';
import Requisitos from './components/Requisitos';
import Dados from './components/Dados';
import Tabela from './components/Tabela';
import Valores from './components/Valores';
import Anexos from './components/Anexos';
import ModalRequisitos from './components/modais/RequisitoModal';
import { DropdownModel } from '@/common/models/geral/DropdownModel';
import helpers from '@/common/utils/helpers';
import Derivas from '../certificados/definir_derivas';

export default {
  components: {
    Requisitos,
    Dados,
    Tabela,
    Valores,
    Anexos,
    ModalRequisitos,
    Derivas,
  },
  props: [
    'ordemServicoId',
    'instrumentoId',
    'instrumentoAnaliseTecnicaId',
    'calibracaoId',
  ],
  data() {
    return {
      indiceAba: 0,
      indiceAbaAtual: 0,
      abasLiberadas: {
        tabelas: false,
        valores: false,
      },
      ordemServico: new OrdemServicoModel({}),
      calibracao: new CalibracaoModel({
        ordemServicoId: this.ordemServicoId,
      }),
      areaAcreditacaoId: null,
      tabelas: [],
      tabelaSelecionada: {
        id: null,
      },
      dadosRequisitosClienteFormatado: [],
      watchs: {
        localExecucao: null,
        dadosCalibracao: null,
      },
      dadosAlterados: false,
      instrumento: new InstrumentoModel({}),
      calibracaoInexistente: false,
    };
  },
  computed: {
    tituloPagina: function () {
      if (
        this.$route.name != 'calibracao-visualizar' &&
        this.calibracao.podeCalibrar
      )
        return this.$t('modulos.calibracao.titulos.pagina');
      return this.$t('modulos.calibracao.titulos.visualizar');
    },
    faixas: function () {
      return this.calibracao.faixas.map((el) => {
        return {
          text: el.descricao,
          value: el.id,
        };
      });
    },
    badges: function () {
      let instrumento = this.ordemServico.ordensServicosInstrumentos.find(
        (el) =>
          el.id == this.instrumentoId ||
          el.instrumentoId == this.calibracao.instrumentoId
      );
      return {
        codigo: instrumento?.codigoTag,
        tipo: instrumento?.tipoInstrumento.nome,
        os: this.ordemServico.codigo,
        tipoId: instrumento?.tipoInstrumento?.id,
        certificadoSubstituido: this.calibracao.certificadoSubstituido?.text,
      };
    },
    opcoesTabelas: function () {
      return this.tabelas.map((el) => {
        return {
          value: el.tabela.id,
          text: `${el.procedimentoEletronico.nome} - ${el.tabela.nome}${
            el.unidadeMedida
              ? ` - (${helpers.formatarNumeroComPrecisao(
                  el.amplitudeMinima
                )} a ${helpers.formatarNumeroComPrecisao(el.amplitudeMaxima)}) ${
                  el.unidadeMedida.nome
                }`
              : ''
          }`,
        };
      });
    },
    exibirDerivas: function () {
      return (
        this.$route.name == 'calibracao-visualizar' &&
        this.calibracao.situacaoCertificado == 'Assinado' &&
        this.instrumento.flagPadrao
      );
    },
  },
  watch: {
    indiceAbaAtual: function () {
      if (this.indiceAbaAtual == 2) {
        this.$refs.valores.inicializarTela();
      }
    },
  },
  async mounted() {
    this.$store.dispatch(
      'Layout/alterarTituloPagina',
      this.$t('modulos.calibracao.titulos.pagina')
    );

    if ((!this.ordemServicoId || !this.instrumentoId) && !this.calibracaoId)
      this.$router.push({
        name: 'ordem-servico',
      });
    if (this.$route.name != 'calibracao-visualizar') {
      await this.buscarOrdemServico();
      // if (this.calibracao.podeCalibrar) this.atualizarOrdemServico();
      this.buscarRequisitos();
      this.definirTecnicoExecutante();
    } else {
      this.$store.dispatch(
        'Layout/alterarTituloPagina',
        this.$t('modulos.calibracao.titulos.visualizar')
      );
      this.buscarCalibracao();
    }
  },
  methods: {
    voltarAba: function () {
      this.indiceAba = this.indiceAbaAtual;
    },
    salvarAoMudar() {
      if (this.$route.name == 'calibracao-visualizar') {
        this.indiceAbaAtual = this.indiceAba;
        return;
      }
      let alterado = true;
      switch (this.indiceAbaAtual) {
        case 0:
          alterado = this.dadosAlterados;
          break;
        case 1:
          alterado = this.$refs.tabelas.foiAlterado();
          break;
        case 2:
          alterado = this.$refs.valores.foiAlterado();
          break;
        default:
          alterado = false;
          break;
      }
      if (alterado) {
        let valido = false;
        let funcao = null;
        let ctx = this;
        switch (this.indiceAbaAtual) {
          case 0:
            valido = this.$refs.dados.validar();
            funcao = this.$refs.dados.salvar;
            break;
          case 1:
            valido = this.$refs.tabelas.validar();
            funcao = this.$refs.tabelas.salvar;
            break;
          case 2:
            valido = true;
            funcao = async function (proximaAba) {
              ctx.$store.dispatch(
                'Layout/iniciarCarregamento',
                'Salvando tabelas'
              );
              await ctx.$refs.valores.salvarMultiplasTabelas();
              ctx.indiceAbaAtual = proximaAba;
            };
            break;
          default:
            valido = true;
            break;
        }
        if (!valido) {
          this.$nextTick(() => {
            this.indiceAba = this.indiceAbaAtual;
          });
          return;
        }
        if (!funcao) {
          this.indiceAbaAtual = this.indiceAba;
          return;
        }
        funcao(this.indiceAba);
      } else {
        this.indiceAbaAtual = this.indiceAba;
      }
    },
    dadosRequisitos: function (dados) {
      this.dadosRequisitosClienteFormatado = dados;
    },
    mudarAba: function (indice) {
      this.indiceAba = indice;
    },
    continuarDados: async function (proximaAba) {
      await this.buscarCalibracao();
      this.$store.dispatch('Layout/iniciarCarregamento', 'Buscando Tabelas');
      if (this.$refs.tabelas)
        await this.$refs.tabelas.buscarTabelasProcedimento();

      setTimeout(() => {
        this.indiceAbaAtual = proximaAba;
        this.indiceAba = proximaAba;
        this.$store.dispatch('Layout/terminarCarregamento');
      }, 500);
    },
    buscarCalibracao: async function () {
      this.$store.dispatch('Layout/iniciarCarregamento');
      this.abasLiberadas = {
        tabelas: false,
        valores: false,
      };
      if (this.watchs.localExecucao != null) this.watchs.localExecucao();
      if (this.watchs.dadosCalibracao != null) this.watchs.dadosCalibracao();
      this.dadosAlterados = false;
      await (this.$route.name != 'calibracao-visualizar'
        ? CalibracaoService.buscar(
            this.calibracao.instrumentoId,
            this.calibracao.ordemServicoId
          )
        : CalibracaoService.buscarPorId(this.calibracaoId)
      )
        .then(async (res) => {
          this.calibracao = new CalibracaoModel(res.data);
          if (this.$refs['dados'])
            this.$refs[
              'dados'
            ].buscarTipoCalibracaoLocalExecucaoAnaliseTecnica();

          await this.buscarInstrumento();

          if (res.data.faixas.length > 0) {
            this.instrumento.instrumentoFaixa = res.data.faixas;
          }

          this.abasLiberadas.tabelas = true;

          this.buscarTabelas();

          if (this.$route.name == 'calibracao-visualizar')
            this.buscarOrdemServico(this.calibracao.ordemServicoId);
          else this.definirTecnicoExecutante();
        })
        .catch(async () => {
          if (this.$refs['dados'])
            await this.$refs[
              'dados'
            ].buscarTipoCalibracaoLocalExecucaoAnaliseTecnica();
          this.$store.dispatch('Layout/terminarCarregamento');
          this.dadosAlterados = true;
          this.buscarTemperaturaUmidadePressaoSetor();
          this.calibracaoInexistente = true;
        })
        .finally(() => {
          this.watchs.localExecucao = this.$watch(
            'calibracao.localExecucaoId',
            () => {
              this.buscarTemperaturaUmidadePressaoSetor();
            }
          );
          this.watchs.dadosCalibracao = this.$watch(
            'calibracao',
            () => {
              this.dadosAlterados = true;
            },
            {
              deep: true,
            }
          );
        });
    },
    buscarInstrumento: function () {
      InstrumentoService.buscar(this.calibracao.instrumentoId).then((res) => {
        this.instrumento = new InstrumentoModel(res.data);
      });
    },
    buscarTemperaturaUmidadePressaoSetor: function () {
      if (!this.calibracao.localExecucaoId) return;

      LocalExecucaoService.buscar(this.calibracao.localExecucaoId).then(
        (local) => {
          if (!local.data.instalacaoCliente) {
            InstrumentoService.buscarUltimaMovimentacao(
              this.calibracao.instrumentoId
            ).then((res) => {
              this.calibracao.temperaturaInicio = res.data.temperatura;
              this.calibracao.temperaturaMeio = res.data.temperatura;
              this.calibracao.temperaturaFim = res.data.temperatura;
              this.calibracao.temperaturaVariacao =
                res.data.temperaturaVariacao;

              this.calibracao.umidadeInicio = res.data.umidadeRelativa;
              this.calibracao.umidadeMeio = res.data.umidadeRelativa;
              this.calibracao.umidadeFim = res.data.umidadeRelativa;
              this.calibracao.umidadeVariacao =
                res.data.umidadeRelativaVariacao;

              this.calibracao.pressaoInicio = res.data.pressaoAtmosferica;
              this.calibracao.pressaoMeio = res.data.pressaoAtmosferica;
              this.calibracao.pressaoFim = res.data.pressaoAtmosferica;
              this.calibracao.pressaoVariacao =
                res.data.pressaoAtmosfericaVariacao;
            });
          } else {
            this.calibracao.temperaturaInicio = 0;
            this.calibracao.temperaturaMeio = 0;
            this.calibracao.temperaturaFim = 0;
            this.calibracao.temperaturaVariacao = 0;

            this.calibracao.umidadeInicio = 0;
            this.calibracao.umidadeMeio = 0;
            this.calibracao.umidadeFim = 0;
            this.calibracao.umidadeVariacao = 0;

            this.calibracao.pressaoInicio = 0;
            this.calibracao.pressaoMeio = 0;
            this.calibracao.pressaoFim = 0;
            this.calibracao.pressaoVariacao = 0;
          }
        }
      );
    },
    continuarTabelas: async function (proximaAba) {
      await this.buscarTabelas();
      if (this.$refs.valores) this.$refs.valores.buscarListaTabelasCalibracao();
      this.indiceAbaAtual = proximaAba;
      this.indiceAba = proximaAba;
    },
    buscarTabelas: async function () {
      this.$store.dispatch('Layout/iniciarCarregamento');
      this.abasLiberadas.valores = false;
      await CalibracaoService.buscarTabelas(this.calibracao.id)
        .then((res) => {
          this.tabelas = res.data.map((el) => {
            el.id = el.identificadorTabela;
            return el;
          });

          if (this.tabelas.length > 0) {
            this.abasLiberadas.valores = true;
            this.tabelaSelecionada.id = this.tabelas[0].tabela.id;
          } else {
            this.tabelaSelecionada.id = null;
          }
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    buscarOrdemServico: async function () {
      this.$store.dispatch('Layout/iniciarCarregamento');
      await OrdemServicoService.buscar(
        this.calibracao.ordemServicoId || this.ordemServicoId
      ).then((res) => {
        this.ordemServico = new OrdemServicoModel(res.data);

        let instrumentoObj = this.ordemServico.ordensServicosInstrumentos.find(
          (el) => {
            return (
              el.id == this.instrumentoId ||
              el.instrumentoId == this.calibracao.instrumentoId ||
              el.instrumentoId == this.instrumentoAnaliseTecnicaId
            );
          }
        );
        this.calibracao.instrumentoId = instrumentoObj.instrumentoId;
        this.areaAcreditacaoId = instrumentoObj.areaAcreditacaoId;
        if (this.$route.name != 'calibracao-visualizar')
          this.buscarCalibracao();
      });
    },
    definirTecnicoExecutante() {
      this.calibracao.usuarioTecnicoExecutanteId = new DropdownModel(
        this.$store.getters['Autenticacao/usuario']
      );
    },
    atualizarOrdemServico: function () {
      OrdemServicoService.atualizarEmAndamento(
        this.ordemServicoId,
        this.instrumentoId
      );
    },
    editarProximaTabela: function () {
      let indice =
        this.tabelas.indexOf(
          this.tabelas.find((el) => el.tabela.id == this.tabelaSelecionada.id)
        ) + 1;
      if (indice == this.tabelas.length) return;

      this.tabelaSelecionada.id = this.tabelas[indice].tabela.id;
    },
    buscarRequisitos: async function () {
      await ParticipanteService.buscaRequisitosParticipante(
        this.ordemServico.clienteParticipante.id,
        'tipoRequisito=3'
      ).then((res) => {
        if (res?.data.length > 0) {
          this.dadosRequisitos(res?.data);
          if (res.data[0].requisitos.length > 0)
            this.$refs.modalRequisitos.abrirModal();
        }
      });
    },
  },
  beforeRouteLeave(to, from, next) {
    if (
      to.name !== 'login' &&
      this.$refs.valores !== undefined &&
      this.$refs.valores.foiAlterado() &&
      this.$route.name != 'calibracao-visualizar' &&
      this.calibracao.podeCalibrar
    ) {
      this.confirmar(
        this.$t('geral.titulos.atencao'),
        this.$t(
          'modulos.calibracao.valores.alertas.confirmar_sair_sem_salvar_calibracao'
        )
      )
        .then(() => {
          next();
        })
        .catch(() => {
          next(false);
        });
    } else {
      next();
    }
  },
};
</script>
